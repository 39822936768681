<template>
  <div class="Login4Digit">
    <div v-if="commandRunning" class="CommandRunning">
      <div class="Upper">
        <div
          v-if="
            !dayClosed &&
            !terminalLocked &&
            !employeeNotFoundWithCode &&
            !employeeNotAllowedToLoginWith4Digit &&
            !supervisorNotFoundWithCode &&
            !supervisorNotAllowedToLoginWith4Digit &&
            !unknownError
          "
        >
          <i class="fas fa-user-lock fa-3x"></i>
        </div>
        <div
          v-if="employeeNotFoundWithCode || supervisorNotFoundWithCode || unknownError || terminalLocked"
        >
          <i class="fas fa-exclamation-triangle fa-3x"></i>
        </div>
        <div
          v-if="
            employeeNotAllowedToLoginWith4Digit || supervisorNotAllowedToLoginWith4Digit
          "
        >
          <i class="fas fa-user-lock fa-3x"></i>
        </div>
        <div v-if="dayClosed"><i class="fas fa-user-lock fa-3x"></i></div>
      </div>
      <div class="Middle">
        <div
          v-if="
            !dayClosed &&
            !terminalLocked &&
            !employeeNotFoundWithCode &&
            !employeeNotAllowedToLoginWith4Digit &&
            !supervisorNotFoundWithCode &&
            !supervisorNotAllowedToLoginWith4Digit &&
            !unknownError
          "
        >
          Der eingegebene Code wird überprüft.
        </div>
        <div v-if="employeeNotFoundWithCode || supervisorNotFoundWithCode">
          Der eingegebene Code ist falsch.
        </div>
        <div
          v-if="
            employeeNotAllowedToLoginWith4Digit || supervisorNotAllowedToLoginWith4Digit
          "
        >
          Die Anmeldung mit dem 4-stelligen Code wurde für Sie deaktiviert.
        </div>
        <div v-if="dayClosed">
          Der aktuelle Tag wurde von einem Vorgesetzten vorzeitig gesperrt.
        </div>
        <div v-if="terminalLocked">
          Die Terminal-Codes sind nicht mehr gültig
        </div>
        <div v-if="unknownError" style="font-size: 12px">
          {{ unknownErrorText }}
        </div>
      </div>
      <div class="Lower">
        <div
          v-if="
            !dayClosed &&
            !terminalLocked &&
            !employeeNotFoundWithCode &&
            !employeeNotAllowedToLoginWith4Digit &&
            !supervisorNotFoundWithCode &&
            !supervisorNotAllowedToLoginWith4Digit
          "
        >
          <img src="loading-balls-line.gif" />
        </div>
        <div v-if="employeeNotFoundWithCode || supervisorNotFoundWithCode">
          Es konnte kein Mitarbeiter mit diesem Code gefunden werden.
        </div>
        <div
          v-if="
            employeeNotAllowedToLoginWith4Digit || supervisorNotAllowedToLoginWith4Digit
          "
        >
          Ihr Vorgesetzter kann Ihnen diese Funktion freischalten.
        </div>
        <div v-if="dayClosed">
          Es kann heute keine Aktion mehr am Stempel-Terminal durchgeführt werden.
        </div>
        <div v-if="terminalLocked">
          {{ $parent.terminalId }} &bull; {{ $parent.accessToken }}
        </div>
      </div>
    </div>
    <div v-if="!commandRunning" class="LoginLeft">
      <div class="Upper">
        <div class="Employee" v-if="$parent.employeeLogin">Mitarbeiter - Login</div>
        <div class="Admin" v-if="!$parent.employeeLogin">Administrator - Login</div>
      </div>
      <div class="Lower">
        <span
          v-bind:style="{
            'border-bottom':
              arrIndex <= 0
                ? arrIndex == 0
                  ? '2px solid #7f7f7f'
                  : '1px solid #c5c5c5'
                : '1px solid #fff',
          }"
          >{{ visibleCode[0] }}</span
        >
        <span
          v-bind:style="{
            'border-bottom':
              arrIndex <= 1
                ? arrIndex == 1
                  ? '2px solid #7f7f7f'
                  : '1px solid #c5c5c5'
                : '1px solid #fff',
          }"
          >{{ visibleCode[1] }}</span
        >
        <span
          v-bind:style="{
            'border-bottom':
              arrIndex <= 2
                ? arrIndex == 2
                  ? '2px solid #7f7f7f'
                  : '1px solid #c5c5c5'
                : '1px solid #fff',
          }"
          >{{ visibleCode[2] }}</span
        >
        <span
          v-bind:style="{
            'border-bottom':
              arrIndex <= 3
                ? arrIndex == 3
                  ? '2px solid #7f7f7f'
                  : '1px solid #c5c5c5'
                : '1px solid #fff',
          }"
          >{{ visibleCode[3] }}</span
        >
      </div>
    </div>
    <div v-if="!commandRunning" class="LoginMiddle">
      <div class="Background">
        <div
          class="Button"
          v-on:click="TapButton(1)"
          v-bind:style="{
            color: 'rgb(' + color_r[1] + ',' + color_g[1] + ',' + color_b[1] + ')',
            'background-color':
              'rgb(' +
              background_color_r[1] +
              ',' +
              background_color_g[1] +
              ',' +
              background_color_b[1] +
              ')',
          }"
        >
          1
        </div>
        <div
          class="Button"
          v-on:click="TapButton(2)"
          v-bind:style="{
            color: 'rgb(' + color_r[2] + ',' + color_g[2] + ',' + color_b[2] + ')',
            'background-color':
              'rgb(' +
              background_color_r[2] +
              ',' +
              background_color_g[2] +
              ',' +
              background_color_b[2] +
              ')',
          }"
        >
          2
        </div>
        <div
          class="Button"
          v-on:click="TapButton(3)"
          v-bind:style="{
            color: 'rgb(' + color_r[3] + ',' + color_g[3] + ',' + color_b[3] + ')',
            'background-color':
              'rgb(' +
              background_color_r[3] +
              ',' +
              background_color_g[3] +
              ',' +
              background_color_b[3] +
              ')',
          }"
        >
          3
        </div>
        <div class="Clear"></div>

        <div
          class="Button"
          v-on:click="TapButton(4)"
          v-bind:style="{
            color: 'rgb(' + color_r[4] + ',' + color_g[4] + ',' + color_b[4] + ')',
            'background-color':
              'rgb(' +
              background_color_r[4] +
              ',' +
              background_color_g[4] +
              ',' +
              background_color_b[4] +
              ')',
          }"
        >
          4
        </div>
        <div
          class="Button"
          v-on:click="TapButton(5)"
          v-bind:style="{
            color: 'rgb(' + color_r[5] + ',' + color_g[5] + ',' + color_b[5] + ')',
            'background-color':
              'rgb(' +
              background_color_r[5] +
              ',' +
              background_color_g[5] +
              ',' +
              background_color_b[5] +
              ')',
          }"
        >
          5
        </div>
        <div
          class="Button"
          v-on:click="TapButton(6)"
          v-bind:style="{
            color: 'rgb(' + color_r[6] + ',' + color_g[6] + ',' + color_b[6] + ')',
            'background-color':
              'rgb(' +
              background_color_r[6] +
              ',' +
              background_color_g[6] +
              ',' +
              background_color_b[6] +
              ')',
          }"
        >
          6
        </div>
        <div class="Clear"></div>

        <div
          class="Button"
          v-on:click="TapButton(7)"
          v-bind:style="{
            color: 'rgb(' + color_r[7] + ',' + color_g[7] + ',' + color_b[7] + ')',
            'background-color':
              'rgb(' +
              background_color_r[7] +
              ',' +
              background_color_g[7] +
              ',' +
              background_color_b[7] +
              ')',
          }"
        >
          7
        </div>
        <div
          class="Button"
          v-on:click="TapButton(8)"
          v-bind:style="{
            color: 'rgb(' + color_r[8] + ',' + color_g[8] + ',' + color_b[8] + ')',
            'background-color':
              'rgb(' +
              background_color_r[8] +
              ',' +
              background_color_g[8] +
              ',' +
              background_color_b[8] +
              ')',
          }"
        >
          8
        </div>
        <div
          class="Button"
          v-on:click="TapButton(9)"
          v-bind:style="{
            color: 'rgb(' + color_r[9] + ',' + color_g[9] + ',' + color_b[9] + ')',
            'background-color':
              'rgb(' +
              background_color_r[9] +
              ',' +
              background_color_g[9] +
              ',' +
              background_color_b[9] +
              ')',
          }"
        >
          9
        </div>
        <div class="Clear"></div>

        <div
          class="Button"
          v-on:click="TapButton(10)"
          v-bind:style="{
            color: 'rgb(' + color_r[10] + ',' + color_g[10] + ',' + color_b[10] + ')',
            'background-color':
              'rgb(' +
              background_color_r[10] +
              ',' +
              background_color_g[10] +
              ',' +
              background_color_b[10] +
              ')',
          }"
        >
          C
        </div>
        <div
          class="Button"
          v-on:click="TapButton(0)"
          v-bind:style="{
            color: 'rgb(' + color_r[0] + ',' + color_g[0] + ',' + color_b[0] + ')',
            'background-color':
              'rgb(' +
              background_color_r[0] +
              ',' +
              background_color_g[0] +
              ',' +
              background_color_b[0] +
              ')',
          }"
        >
          0
        </div>
        <div
          class="Button"
          v-on:click="TapButton(11)"
          v-bind:style="{
            color: 'rgb(' + color_r[11] + ',' + color_g[11] + ',' + color_b[11] + ')',
            'background-color':
              'rgb(' +
              background_color_r[11] +
              ',' +
              background_color_g[11] +
              ',' +
              background_color_b[11] +
              ')',
          }"
          style="font-size: 30px"
        >
          <i class="fas fa-backspace"></i>
        </div>
        <div class="Clear"></div>
      </div>
    </div>
    <div v-if="!commandRunning" class="LoginRight">
      <div class="Upper">Guten Tag!</div>
      <div class="Lower">
        Bitte geben Sie Ihren <br />
        <strong>4 stelligen Code</strong> <br />
        über das Tastenfeld ein.<br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login4Digit",
  props: {},
  data: function () {
    return {
      dayClosed: false,
      terminalLocked: false,
      employeeNotFoundWithCode: false,
      supervisorNotFoundWithCode: false,
      employeeNotAllowedToLoginWith4Digit: false,
      supervisorNotAllowedToLoginWith4Digit: false,
      commandRunning: false,
      unknownError: false,
      unknownErrorText: "",
      arrIndex: 0,
      visibleCodeTimestamps: [Date.now(), Date.now(), Date.now(), Date.now()],
      visibleCode: [" ", " ", " ", " "],
      hiddenCode: [null, null, null, null],
      color_r: [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178],
      color_g: [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178],
      color_b: [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178],
      background_color_r: [255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255],
      background_color_g: [255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255],
      background_color_b: [255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255],
    };
  },
  created: function () {},
  destroyed() {},
  mounted: function () {
    let view = this;
    view.Reset();
    let intervalColor = 30;

    /*
    view.myInterval = setInterval(function(){
      for(let i = 0;i< view.color_r.length;i++){
        if(view.$parent.employeeLogin){
          if(view.color_r[i] > 178){ view.color_r[i] -= 26; view.color_r[i] = Math.max(178,view.color_r[i]); }
          if(view.color_g[i] > 178){ view.color_g[i] -= 26; view.color_g[i] = Math.max(178,view.color_g[i]); }
          if(view.color_b[i] > 178){ view.color_b[i] -= 26; view.color_b[i] = Math.max(178,view.color_b[i]); }
        }else{
          if(view.color_r[i] > 231){ view.color_r[i] -= 8; view.color_r[i] = Math.max(231,view.color_r[i]); }
          if(view.color_g[i] > 110){ view.color_g[i] -= 50; view.color_g[i] = Math.max(110,view.color_g[i]); }
          if(view.color_b[i] > 19){ view.color_b[i] -= 80; view.color_b[i] = Math.max(19,view.color_b[i]); }
        }
        if(view.$parent.employeeLogin){
          if(view.background_color_r[i] < 255){ view.background_color_r[i] += 50; view.background_color_r[i] = Math.max(178,view.background_color_r[i]); }
          if(view.background_color_g[i] < 255){ view.background_color_g[i] += 50; view.background_color_g[i] = Math.max(178,view.background_color_g[i]); }
          if(view.background_color_b[i] < 255){ view.background_color_b[i] += 50; view.background_color_b[i] = Math.max(178,view.background_color_b[i]); }
        }else{
          if(view.background_color_r[i] < 255){ view.background_color_r[i] += 8; view.background_color_r[i] = Math.max(178,view.background_color_r[i]); }
          if(view.background_color_g[i] < 255){ view.background_color_g[i] += 50; view.background_color_g[i] = Math.max(178,view.background_color_g[i]); }
          if(view.background_color_b[i] < 255){ view.background_color_b[i] += 80; view.background_color_b[i] = Math.max(178,view.background_color_b[i]); }
        }
      }
      
      for(let i = 0;i< view.visibleCode.length;i++){
        if(view.hiddenCode[i] != null){
          if(Date.now() - view.visibleCodeTimestamps[i] > 1500){
            view.visibleCode[i] = '*';
          }
        }
      }

      view.$forceUpdate();
    }, 100);
*/
  },
  methods: {
    Reset: function () {
      this.dayClosed = false;
      this.terminalLocked = false;
      this.employeeNotFoundWithCode = false;
      this.supervisorNotFoundWithCode = false;
      this.employeeNotAllowedToLoginWith4Digit = false;
      this.supervisorNotAllowedToLoginWith4Digit = false;
      this.commandRunning = false;
      this.arrIndex = 0;
      this.visibleCodeTimestamps = [Date.now(), Date.now(), Date.now(), Date.now()];
      this.visibleCode = [" ", " ", " ", " "];
      this.hiddenCode = [null, null, null, null];
      if (this.$parent.employeeLogin) {
        this.color_r = [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178];
        this.color_g = [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178];
        this.color_b = [178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178, 178];
      } else {
        this.color_r = [231, 231, 231, 231, 231, 231, 231, 231, 231, 231, 231, 231];
        this.color_g = [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110];
        this.color_b = [19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19];
      }

      this.background_color_r = [
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
      ];
      this.background_color_g = [
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
      ];
      this.background_color_b = [
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
        255,
      ];
    },
    TapButton: function (key) {
      if (key == 10) {
        this.arrIndex = 0;
        this.visibleCodeTimestamps = [Date.now(), Date.now(), Date.now(), Date.now()];
        this.visibleCode = [" ", " ", " ", " "];
        this.hiddenCode = [null, null, null, null];
        /*
        this.color_r[key] = 255;
        this.color_g[key] = 255;
        this.color_b[key] = 255;
        if(this.$parent.employeeLogin){
          this.background_color_r[key] = 115.5;
          this.background_color_g[key] = 115.5;
          this.background_color_b[key] = 115.5;
        }else{
          this.background_color_r[key] = 231;
          this.background_color_g[key] = 110;
          this.background_color_b[key] = 19;
        }
        */
      } else if (key == 11) {
        this.arrIndex--;
        if (this.arrIndex < 0) {
          this.arrIndex = 0;
        }
        this.hiddenCode[this.arrIndex] = null;
        this.visibleCode[this.arrIndex] = " ";
        this.visibleCodeTimestamps[this.arrIndex] = Date.now();

        /*
        this.color_r[key] = 255;
        this.color_g[key] = 255;
        this.color_b[key] = 255;
        if(this.$parent.employeeLogin){
          this.background_color_r[key] = 115.5;
          this.background_color_g[key] = 115.5;
          this.background_color_b[key] = 115.5;
        }else{
          this.background_color_r[key] = 231;
          this.background_color_g[key] = 110;
          this.background_color_b[key] = 19;
        }
        */
      } else {
        if (this.hiddenCode[this.arrIndex] == null) {
          this.hiddenCode[this.arrIndex] = key;
          this.visibleCode[this.arrIndex] = key;
          //this.visibleCode[this.arrIndex] = '*';
          this.visibleCodeTimestamps[this.arrIndex] = Date.now();
          this.arrIndex++;

          if (this.arrIndex > 3) {
            this.commandRunning = true;
            this.RequestEmployee4DigitLogin();
          }
        }

        /*
        this.color_r[key] = 255;
        this.color_g[key] = 255;
        this.color_b[key] = 255;
        if(this.$parent.employeeLogin){
          this.background_color_r[key] = 115.5;
          this.background_color_g[key] = 115.5;
          this.background_color_b[key] = 115.5;
        }else{
          this.background_color_r[key] = 231;
          this.background_color_g[key] = 110;
          this.background_color_b[key] = 19;
        }
        */
      }

      this.$forceUpdate();
    },
    RequestEmployee4DigitLogin: function () {
      let view = this;
      if (!this.$parent.employeeLogin) {
        return this.RequestAdmin4DigitLogin();
      } else {
        view.dayClosed = false;
        view.terminalLocked = false;
        view.employeeNotFoundWithCode = false;
        view.employeeNotAllowedToLoginWith4Digit = false;
        view.$parent.hasAdminEmployee = false;

        view.$parent.supervisorFirstName = "-";
        view.$parent.supervisorLastName = "-";
        view.$parent.supervisorPictureServer = null;
        view.$parent.supervisorPictureFileId = null;
        view.$parent.supervisorPictureAccessToken = null;
        view.$parent.supervisorLogin = false;

        let url =
          "/api/getLoginWith4Digit?id=" +
          this.$parent.terminalId +
          "&token=" +
          this.$parent.accessToken;
        view.$parent.LATEST_REQUEST = Date.now();
        axios({
          method: "post",
          headers: {},
          url: url,
          data: {
            code:
              "" +
              view.hiddenCode[0] +
              view.hiddenCode[1] +
              view.hiddenCode[2] +
              view.hiddenCode[3],
          },
        })
          .then((response) => {
            view.$parent.employeeFirstName = response.data.data.firstName;
            view.$parent.employeeLastName = response.data.data.lastName;
            view.$parent.loginWithoutPlanshift = response.data.data.loginWithoutPlanshift;
            view.$parent.hasAdminEmployee = response.data.data.hasAdminEmployee;

            view.$parent.employeePictureServer = response.data.data.employeePictureServer;
            view.$parent.employeePictureFileId = response.data.data.employeePictureFileId;
            view.$parent.employeePictureAccessToken =
              response.data.data.employeePictureAccessToken;
            view.$parent.page = "mainOverview";
          })
          .catch(function (error) {
            view.unknownError = true;
            if (error && error.response && error.response.status) {
              view.unknownErrorText = error.response;

              if (error.response.status == 503) {
                if (error.response.data && error.response.data.type == "Maintenance") {
                  view.$parent.MAINTENANCE = true;
                }
              } else if (error.response.status == 403) {
                if (error.response.data.code == 0) {
                  view.unknownError = false;
                  view.employeeNotFoundWithCode = true;
                  setTimeout(function () {
                    view.Reset();
                  }, 4000);
                } else if (error.response.data.code == 1) {
                  view.unknownError = false;
                  view.employeeNotAllowedToLoginWith4Digit = true;
                  setTimeout(function () {
                    view.Reset();
                    view.$parent.page = "login-qr";
                  }, 5000);
                }
              } else if (error.response.status == 423) {
                view.unknownError = false;
                view.dayClosed = true;
                setTimeout(function () {
                  view.Reset();
                }, 5000);
              } else if (error.response.status == 422) {
                view.unknownError = false;
                view.terminalLocked = true;
                setTimeout(function () {
                  view.Reset();
                }, 5000);
              } else {
                setTimeout(function () {
                  view.Reset();
                }, 5000);
              }
            } else {
              view.unknownErrorText = "Response nicht verarbeitbar";
              setTimeout(function () {
                view.Reset();
              }, 5000);
            }
          });
      }
    },

    RequestAdmin4DigitLogin: function () {
      let view = this;
      view.dayClosed = false;
      view.terminalLocked = false;
      view.supervisorNotFoundWithCode = false;
      view.supervisorNotAllowedToLoginWith4Digit = false;
      view.$parent.hasAdminEmployee = false;

      view.$parent.supervisorFirstName = "-";
      view.$parent.supervisorLastName = "-";
      view.$parent.supervisorPictureServer = null;
      view.$parent.supervisorPictureFileId = null;
      view.$parent.supervisorPictureAccessToken = null;
      view.$parent.supervisorLogin = false;
      view.$parent.employeeLogin = true;

      let url =
        "/api/getLoginSupervisorWith4Digit?id=" +
        this.$parent.terminalId +
        "&token=" +
        this.$parent.accessToken;
      view.$parent.LATEST_REQUEST = Date.now();
      axios({
        method: "post",
        headers: {},
        url: url,
        data: {
          code:
            "" +
            view.hiddenCode[0] +
            view.hiddenCode[1] +
            view.hiddenCode[2] +
            view.hiddenCode[3],
        },
      })
        .then((response) => {
          view.$parent.supervisorLogin = response.data.data.supervisorLogin;
          view.$parent.employeeLogin = !response.data.data.supervisorLogin;

          view.$parent.employeeFirstName = response.data.data.firstName;
          view.$parent.employeeLastName = response.data.data.lastName;

          view.$parent.supervisorFirstName = response.data.data.supervisorFirstName;
          view.$parent.supervisorLastName = response.data.data.supervisorLastName;

          view.$parent.loginWithoutPlanshift = response.data.data.loginWithoutPlanshift;
          view.$parent.hasAdminEmployee = response.data.data.hasAdminEmployee;

          view.$parent.employeePictureServer = response.data.data.employeePictureServer;
          view.$parent.employeePictureFileId = response.data.data.employeePictureFileId;
          view.$parent.employeePictureAccessToken =
            response.data.data.employeePictureAccessToken;

          view.$parent.supervisorPictureServer =
            response.data.data.supervisorPictureServer;
          view.$parent.supervisorPictureFileId =
            response.data.data.supervisorPictureFileId;
          view.$parent.supervisorPictureAccessToken =
            response.data.data.supervisorPictureAccessToken;
          view.$parent.page = "mainOverview";
        })
        .catch(function (error) {
          if (error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.$parent.MAINTENANCE = true;
            }
          } else if (error.response.status == 403) {
            if (error.response.data.code == 0) {
              view.supervisorNotFoundWithCode = true;
              setTimeout(function () {
                view.Reset();
              }, 4000);
            } else if (error.response.data.code == 1) {
              view.supervisorNotAllowedToLoginWith4Digit = true;
              setTimeout(function () {
                view.Reset();
                view.$parent.page = "login-qr";
              }, 5000);
            }
          } else if (error.response.status == 423) {
            view.dayClosed = true;
            setTimeout(function () {
              view.Reset();
            }, 8000);
          } else if (error.response.status == 422) {
            view.unknownError = false;
            view.terminalLocked = true;
            setTimeout(function () {
              view.Reset();
            }, 5000);
          } else {
            setTimeout(function () {
              view.Reset();
            }, 1000);
          }
          console.log(error.response.status);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Login4Digit {
  background-color: #fff;
  width: 100%;
  height: 365px;
  text-align: center;
  overflow: hidden;
}

.Login4Digit > .CommandRunning {
  width: calc(800px - (2 * var(--content-margin)));
  height: 365px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
}
.Login4Digit > .CommandRunning > .Upper {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 90px;
  margin-bottom: 15px;

  font-family: DistrictProMedium;
  color: #808080;
  font-size: 20px;
}
.Login4Digit > .CommandRunning > .Middle {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 25px;

  font-family: DistrictProMedium;
  color: #b2b2b2;
  font-size: 22px;
}
.Login4Digit > .CommandRunning > .Lower {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 25px;
  margin-bottom: 15px;

  font-family: DistrictProBook;
  color: #b2b2b2;
  font-size: 18px;
}

.LoginLeft {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginLeft > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginLeft > .Upper > .Employee {
  color: #7f7f7f;
}
.LoginLeft > .Upper > .Admin {
  color: #e76e13;
}
.LoginLeft > .Lower {
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 50px;
}
.LoginLeft > .Lower > span {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.LoginMiddle {
  background-color: #fff;
  float: left;
  width: 254px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginMiddle > .Background {
  background-color: #e5e5e5;
  width: 254px;
  height: 335px;
  margin-top: 15px;
  margin-left: 0px;
  overflow: hidden;
  border-radius: 15px;
}
.LoginMiddle > .Background > .Button {
  user-select: none;

  float: left;
  width: 70px;
  height: 70px;

  margin-top: 6px;
  margin-bottom: 5px;

  margin-left: 6px;
  margin-right: 5px;

  overflow: hidden;
  border-radius: 15px;

  font-family: DistrictProBold;
  font-size: 42px;
  line-height: 70px;

  transition: color 100ms linear, background-color 100ms linear;
}

.LoginMiddle > .Background > :nth-child(1) {
  margin-left: 11px;
  margin-top: 11px;
}
.LoginMiddle > .Background > :nth-child(2) {
  margin-top: 11px;
}
.LoginMiddle > .Background > :nth-child(3) {
  margin-right: 11px;
  margin-top: 11px;
}

.LoginMiddle > .Background > :nth-child(5) {
  margin-left: 11px;
}
.LoginMiddle > .Background > :nth-child(7) {
  margin-right: 11px;
}

.LoginMiddle > .Background > :nth-child(9) {
  margin-left: 11px;
}
.LoginMiddle > .Background > :nth-child(11) {
  margin-right: 11px;
}

.LoginMiddle > .Background > :nth-child(13) {
  margin-left: 11px;
  margin-bottom: 11px;
}
.LoginMiddle > .Background > :nth-child(14) {
  margin-bottom: 11px;
}
.LoginMiddle > .Background > :nth-child(15) {
  margin-right: 11px;
  margin-bottom: 11px;
}

.LoginMiddle > .Background > .Clear {
  clear: both;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.LoginRight {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginRight > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginRight > .Lower {
  margin-top: 25px;
  background-color: #fff;
  width: 100%;
  height: 200px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 25px;
}
</style>
