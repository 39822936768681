<template>
  <div class="LoginQR">
    <div class="LoginLeft">
      <div class="Upper">Terminal entsperren!</div>
      <div class="Lower">QR - Code</div>
    </div>
    <div class="LoginMiddle">
      <img class="Image" v-bind:src="qrCode" />
    </div>
    <div class="LoginRight">
      <div class="Lower">
        Bitte scannen Sie<br />
        mit der MD-App<br />
        den <strong>QR-Code</strong> ein.<br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LockedTerminal",
  props: {},
  data: function () {
    return {
      qrCode: "",
      qrTerminalTimeout: undefined,
    };
  },
  created: function () {},
  destroyed() {
    if (this.qrTerminalTimeout != undefined) {
      clearTimeout(this.qrTerminalTimeout);
    }
  },
  mounted: function () {
    this.RequestNew();
  },
  methods: {
    RequestNew: function () {
      let view = this;
      view.qrTerminalTimeout = setTimeout(function qrTerminal() {
        view.RequestTerminalQRCode();
        view.qrTerminalTimeout = setTimeout(qrTerminal, 300000);
      }, 0);
    },

    RequestTerminalQRCode: function () {
      let view = this;
      let url = "/api/getQRCodeForTerminal";
      view.$parent.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.qrCode = response.data.image;
          view.$parent.prevTerminalId = view.$parent.tempTerminalId;
          view.$parent.tempTerminalId = response.data.terminalId;
          view.$parent.listenToUnlockTerminalChanged();
          view.$forceUpdate();
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.$parent.MAINTENANCE = true;
            }
          }
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.LoginQR {
  background-color: #fff;
  width: 100%;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginLeft {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginLeft > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginLeft > .Lower {
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 50px;
}
.LoginLeft > .Lower > span {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.LoginMiddle {
  background-color: #fff;
  float: left;
  width: 254px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginMiddle > .Image {
  margin-top: 55px;
}

.LoginRight {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginRight > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginRight > .Lower {
  margin-top: 135px;
  background-color: #fff;
  width: 100%;
  height: 200px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 25px;
}
</style>
