<template>
  <div class="Logout">
    <div class="Upper">
      Bis bald!
    </div>
    <div class="Middle">
      Du wirst abgemeldet.<br/><br/>
      <img src="loading-balls-line.gif"/>
    </div>
    <div class="Lower">
      <br/>
      <button type="button" class="btn-secondary" v-on:click="logoutButton()">Hier drücken - Um sofort zur Anmeldung zu kommen</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechnicAccess',
  props: {},
  data: function(){
    return {
    };
  },
  created: function(){},
  mounted: function(){
    let view = this;
      if(view.$parent.defaultLogin == '4digit'){
        view.$parent.page = 'login-4-digit';
      }else if(view.$parent.defaultLogin == 'qr'){
        view.$parent.page = 'login-qr';
      }else{
        view.$parent.page = 'login-4-digit';
      }
  },
  destroyed() {
  },
  methods: {
    logoutButton: function(){
      let view = this;
      if(view.$parent.defaultLogin == '4digit'){
        view.$parent.page = 'login-4-digit';
      }else if(view.$parent.defaultLogin == 'qr'){
        view.$parent.page = 'login-qr';
      }else{
        view.$parent.page = 'login-4-digit';
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .Logout{
    margin-top: 20px;
    background-color: #fff;
    width: 100%;
    height: 325px;
    text-align: center;
    overflow: hidden;
  }
  .Logout .Upper{
    text-align: center;
    width: 100%;
    background-color: #fff;
    margin-top:90px;
    margin-bottom:15px;

    font-family: DistrictProMedium;
    color: #808080;
    font-size: 20px;
  }
  .Logout .Middle{
    text-align: center;
    width: 100%;
    background-color: #fff;
    margin-top:15px;
    margin-bottom:25px;

    font-family: DistrictProMedium;
    color: #b2b2b2;
    font-size: 18px;
  }
  .Logout .Lower{
    text-align: center;
    width: 100%;
    background-color: #fff;
    margin-top:25px;
    margin-bottom:15px;

    font-family: DistrictProMedium;
    color: #b2b2b2;
    font-size: 18px;
  }
  .btn-secondary{
    align-items: flex-start;
    box-sizing:border-box;
    display:inline-block;
    height:38px;
    margin-bottom:0px;
    margin-left:0px;
    margin-right:0px;
    margin-top:0px;
    overflow-x:visible;
    overflow-y:visible;
    padding-bottom:6px;
    padding-left:12px;
    padding-right:12px;
    padding-top:6px;
    color:rgb(255, 255, 255);
    background-color:rgb(108, 117, 125);
    border-bottom-color:rgb(108, 117, 125);
    border-bottom-style:solid;
    border-bottom-width:1px;
    border-image-outset:0;
    border-image-repeat:stretch;
    border-image-slice:100%;
    border-image-source:none;
    border-image-width:1;
    border-left-color:rgb(108, 117, 125);
    border-left-style:solid;
    border-left-width:1px;
    border-right-color:rgb(108, 117, 125);
    border-right-style:solid;
    border-right-width:1px;
    border-top-color:rgb(108, 117, 125);
    border-top-style:solid;
    border-top-width:1px;
    cursor:pointer;
    -webkit-border-image:none;
    text-rendering:auto;
    writing-mode:horizontal-tb;
    appearance:button;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    user-select:none;
    -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
    
    font-size:16px;
    font-stretch:100%;
    font-style:bold;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:600;
    letter-spacing:normal;
    line-height:24px;
    text-align:center;
    text-indent:0px;
    text-shadow:none;
    text-size-adjust:100%;
    text-transform:none;
    vertical-align:middle;
    white-space:nowrap;
    word-spacing:0px;

  }
</style>
