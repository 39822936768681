<template>
  <div id="app" class="app">
    <Dashboard/>
  </div>
</template>

<script>
import Dashboard from './components/Dashboard.vue'

export default {
  name: 'App',
  components: {
    Dashboard
  }
}
</script>

<style>
  .app{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
    vertical-align: middle;
  }
</style>
