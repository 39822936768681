import Vue from 'vue'
import Echo from 'laravel-echo'

window.io = require('socket.io-client');
if (window.location.hostname.includes("localhost")) {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    //host: window.location.hostname+':'+location.port // Line for local development and remote socket.io
    host: 'localhost:6001' // Line for local development and local socket.io
  });
} else {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname
  });
}

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
